import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import HomeBackground from '../components/backgrounds/HomeBackground'
import RosesBackground from '../components/backgrounds/RosesBackground';
import FlowerBackground from '../components/backgrounds/FlowerBackground';

import NewsModal from '../components/modals/NewsModal';
import PopOut from '../components/popout/PopOut';

import { SectionContainer, SectionSubContainer } from '../components/containers/SectionContainers';
import RoseCards from '../components/cards/RoseCards';
import LinksBackground from '../components/backgrounds/LinksBackground';

const HomeBody = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;

    h1 {
        font-size: 6rem;
        font-weight: 100;
        color: var(--primary-color);

        @media (max-width: 1200px) {
            font-size: 5rem;
        }

        @media (max-width: 1000px) {
            font-size: 4rem;
        }
    }

    h2 {
        font-size: 3rem;
        font-weight: 100;
        color: var(--primary-color);
        margin: 0;

        @media (max-width: 1200px) {
            font-size: 2.5rem;
        }

        @media (max-width: 1000px) {
            font-size: 2rem;
        }
    }

    h3 { 
        font-size: 2rem;
        font-weight: 100;
        color: var(--primary-color);
        margin: 0;

        @media (max-width: 1200px) {
            font-size: 1.8rem;
        }

        @media (max-width: 1000px) {
            font-size: 1.6rem;
        }
    }

    p {
        color: var(--primary-color);
        font-size: 1.4rem;
        font-weight: 100;

        @media (max-width: 1200px) {
            font-size: 1.2rem;
        }

        @media (max-width: 1000px) {
            font-size: 1rem;
        }
    }

    span {
        color: var(--pink-color);
        font-size: 2rem;
        font-weight: 100;
        margin: 0;

        @media (max-width: 1200px) {
            font-size: 1.8rem;
        }

        @media (max-width: 1000px) {
            font-size: 1.6rem;
        }
    }
`;

const HeroContainer = styled.div`
    width: 80%;
    height: 100%;
    margin: 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    a {
        font-size: 2rem;
        font-weight: 100;
        color: var(--primary-color);
        text-decoration: underline;
        transition: all 0.3s ease-in-out;
        margin: 0;

        @media (max-width: 1200px) {
            font-size: 2.5rem;
        }

        @media (max-width: 1000px) {
            font-size: 2rem;
        }

        &:hover {
            cursor: pointer;
            color: var(--pink-color);
        }
    }
`;

const DescriptionContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 10%;
    width: 80%;
    justify-content: center;
    align-items: center;

    @media (max-width: 850px) {
        width: 60%;
        bottom: 15%;
        flex-direction: column;
    }   

    h3 {
        text-align: center;
    }
`;

const HowitworksSectionContainer = styled(SectionContainer)`
    padding: 10%;

    @media (max-width: 1200px) {
        padding: 5%;
    }
`;

const HowitworksCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: auto;
    min-width: 620px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 1200px) {
        min-width: 520px;
    }

    @media (max-width: 750px) {
        min-width: 380px;
    }

    @media (max-width: 600px) {
        min-width: 300px;
    }
`;

const QuoteContainer = styled.div`
    display: flex;
    flex: 1;
    width: auto;
    flex-direction: column;


    p {
        font-size: 5rem;
        font-weight: 100;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        text-align: left;

        @media (max-width: 1200px) {
            font-size: 4rem;
        }
    }
`;

const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 70%;
    height: auto;
    min-height: 60vh;
    gap: 20px;

    .card-right {
        align-items: flex-end;
        
    }

    h3 {
        font-size: 3rem;
        font-weight: 100;
        color: var(--primary-color);
        margin: 0;
        text-align: ${({ align }) => align ? 'left' : align};

        @media (max-width: 1200px) {
            font-size: 2.5rem;
        }

        @media (max-width: 1000px) {
            font-size: 2rem;
        }
    }

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 850px) {
        width: 90%;
    }
`;



const Home = () => {
    const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
    const NewsMessage = {
        newsHeading: 'Hi there!',
        newsMessage: <>Start Playing now to get 200 free BLUV on sign up! <a href="https://app.byteluv.network/" target="_blank" rel="noreferrer">Learn how to play!</a></>
    };

    // Function to handle scrolling to a hash
    const scrollToHash = () => {
        const hash = window.location.hash;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    // Effect to scroll to hash on load
    useEffect(() => {
        scrollToHash();
        // Adding an event listener to handle hash changes
        window.addEventListener('hashchange', scrollToHash, false);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('hashchange', scrollToHash, false);
        };
    }, []);

    return (
        <HomeBody>
            {isNewsModalOpen && <NewsModal isOpen={isNewsModalOpen} setIsOpen={setIsNewsModalOpen} newsHeading={NewsMessage.newsHeading} newsMessage={NewsMessage.newsMessage} link="https://app.byteluv.network" buttonText="Go Now!" />}
            <PopOut onClick={() => setIsNewsModalOpen(true)} popOutText="News" />
            <HomeBackground>
                <HeroContainer>
                    <h1>
                        ByteLuv
                    </h1>
                    <a href="https://app.byteluv.network" target="_blank" rel="noreferrer">
                        Enter the new world of dating
                    </a>
                    <DescriptionContainer>
                        <h3>
                            Dating made <span>Safe</span>, <span>Transparent</span>, <span>Fair</span> & <span>Fun</span>
                        </h3>
                    </DescriptionContainer>
                </HeroContainer>
            </HomeBackground>
            <RosesBackground>
                <HowitworksSectionContainer id="howitworks">
                    <SectionSubContainer>
                        <QuoteContainer>
                            <p>
                                Random,
                            </p>
                            <p>
                                Freedom,
                            </p>
                            <p>
                                Just like life.
                            </p>
                        </QuoteContainer>
                    </SectionSubContainer>
                    <SectionSubContainer>
                        <h2>
                            Revolutionizing Online Dating
                        </h2>
                        <HowitworksCardContainer>
                            <RoseCards>
                                <h3>
                                    No Algorithms
                                </h3>
                                <p>
                                    Location based Matching, no algorithms. Just like life.
                                </p>
                            </RoseCards>
                            <RoseCards>
                                <h3>
                                    Transparent
                                </h3>
                                <p>
                                    All interactions between users are all on blockchain.
                                </p>
                            </RoseCards>
                            <RoseCards>
                                <h3>
                                    Safe
                                </h3>
                                <p>
                                    All profiles are verified by verifiers in the system.
                                </p>
                            </RoseCards>
                            <RoseCards>
                                <h3>
                                    Fair
                                </h3>
                                <p>
                                    Everyone all starts the same and having more $BLUV does not matter.
                                </p>
                            </RoseCards>
                        </HowitworksCardContainer>
                    </SectionSubContainer>
                </HowitworksSectionContainer>
            </RosesBackground>
            <FlowerBackground>
                <SectionContainer id='bluv-token'>
                    <SectionSubContainer className="left">
                        <CTAContainer className="left">
                            <h3>
                                Loving Differently
                            </h3>
                            <RoseCards>
                                <h3>
                                    Express Love
                                </h3>
                                <p>
                                    Show how much you love someone, by liking them with a limit of 100 a day.
                                </p>
                            </RoseCards>
                            <RoseCards>
                                <h3>
                                    Locations
                                </h3>
                                <p>
                                    See who chose the same location as you, and match with them.
                                </p>
                            </RoseCards>
                            <RoseCards>
                                <h3>
                                    Match & Contact
                                </h3>
                                <p>
                                    Onced matched, you can contact the other player, via telegram.
                                </p>
                            </RoseCards>
                        </CTAContainer>
                    </SectionSubContainer>
                    <SectionSubContainer className="right">
                        <CTAContainer className="right">
                            <h3 align="right">
                                Gamified Dating
                            </h3>
                            <RoseCards align="right">
                                <h3>
                                    UnMatching
                                </h3>
                                <p>
                                    Earn or Lose based on <br /> satisfaction of the other half.
                                </p>
                            </RoseCards>
                            <RoseCards align="right">
                                <h3>
                                    Opportunity
                                </h3>
                                <p>
                                    With only 100 BLUV a day, every location chosen, every like counts.
                                </p>
                            </RoseCards>
                            <RoseCards align="right">
                                <h3>
                                    Fairness
                                </h3>
                                <p>
                                    Everyone plays the same game, no one has an advantage.
                                </p>
                            </RoseCards>
                        </CTAContainer>
                    </SectionSubContainer>
                </SectionContainer>
            </FlowerBackground>
            <LinksBackground>
                <a href="https://app.byteluv.network" target="_blank" rel="noreferrer">
                    Play Now
                </a>
                <a href="https://t.me/ByteLuv" target="_blank" rel="noreferrer">
                    Telegram
                </a>
                <a href="https://wiki.byteluv.network" target="_blank" rel="noreferrer">
                    Tutorial
                </a>
            </LinksBackground>
        </HomeBody>
    )
}

export default Home
