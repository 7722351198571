import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faGithub, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import CardButton from '../buttons/CardButton';
import NavRose from '../../assets/navigation/navigation-rose.svg';
import NavThorns from '../../assets/navigation/navigation-thorns.svg';

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
`;

const NavigationRose = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    transform: rotate(${(props) => (props.$isOpen ? '135deg' : '45deg')});
`;

const NavigationThorns = styled.img`
    position: absolute;
    width: 100%;
    left: ${(props) => (props.$isOpen ? '0' : '-20px')};
    height: auto;
    object-fit: cover;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    transform: rotate(${(props) => (props.$isOpen ? '-45deg' : '-90deg')});
`;

const NavigationContainer = styled.div`
    display: flex;
    position: fixed;
    top: 50px;
    left: 50px;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    box-shadow: ${({ $isOpen }) => ($isOpen ? '0 0 10px 5px rgba(255, 45, 85, 0.5)' : 'none')};
    transform: ${({ $isOpen }) => ($isOpen ? 'scale(1.1)' : 'none')};

    &:hover {
        box-shadow: ${({ $isOpen }) => ($isOpen ? '0 0 10px 5px rgba(255, 255, 255, 0.5)' : '0 0 10px 5px rgba(255, 45, 85, 0.5)')};
        transform: ${({ $isOpen }) => ($isOpen ? 'scale(1.1)' : 'scale(1.05)')};
    }

    @media (max-width: 1000px) {
        top: 40px;
        left: 40px;
        width: 50px;
        height: 50px;
    }

    @media (max-width: 600px) {
        top: 50px;
        left: 20px;
    }
`;

const scaleIn = keyframes`
    0% {
        clip-path: circle(0% at 50px 50px);
    }
    100% {
        clip-path: circle(150% at 50px 50px);
    }
`;

const scaleOut = keyframes` 
    0% {
        clip-path: circle(150% at 50px 50px);
    }
    100% {
        clip-path: circle(0% at 50px 50px);
    }
`;

const FullScreenNavigation = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    background-image: 
        linear-gradient(to right, rgba(255, 45, 85, 0.3) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255, 45, 85, 0.3) 1px, transparent 1px);
    background-size: 20px 20px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
    animation: ${({ $isOpen }) => ($isOpen ? css`${scaleIn} 0.5s ease-in-out forwards` : css`${scaleOut} 0.5s ease-in-out forwards`)};
`;

const SocialLinks = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50px;
    width: 60px;
    height: 50%;
    transform: translateY(-50%);
    flex-direction: column;
    gap: 20px;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1000;

    a {
        color: white;
        font-size: 2rem;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (max-width: 1000px) {
        left: 40px;
        width: 50px;

        a {
            font-size: 1.7rem;
        }
    }

    @media (max-width: 600px) {
        left: 20px;
        width: 50px;
    }
`;

const NavigationSections = styled.div`
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: 5vh 5vw 5vh 15vw;
    height: 90vh;
    justify-content: space-evenly;
    align-items: center;
    animation: ${scaleIn} 0.5s ease-in-out forwards;

    @media (max-width: 1000px) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }

    @media (max-width: 600px) {
        margin: 10vh 20px 10vh 90px;
        width: auto;
    }
`;

const NavigationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30%;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1000px) {
        flex: 1;
        width: auto;
        height: auto;
        min-width: 200px;
        max-width: 50%;
    }

    @media (max-width: 600px) {
        min-width: 150px;
        max-width: 45%;
    }

    @media (max-width: 400px) {
        min-width: 100px;
        max-width: 50%;
    }
    
`;

const NavHeader = styled.h5`
    font-size: 3rem;
    color: white;
    text-align: left;
    font-weight: 100;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

    @media (max-width: 1200px) {
        font-size: 2.5rem;
    }

    @media (max-width: 1000px) {
        font-size: 2.3em;
    }

    @media (max-width: 850px) {
        font-size: 2rem;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
`;

const NavBody = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 10px 0 10px 0;

    @media (max-width: 1000px) {
        flex-direction: column;
        height: auto;
        width: 100%;
        gap: 10px;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 2px;
    background-color: var(--primary-color);
    border-radius: 5px;
`;

const NavigationBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isOpen || isVisible) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isOpen, isVisible]);

    const toggleMenu = () => {
        if (isOpen) {
            setIsOpen(false);
            setTimeout(() => setIsVisible(false), 500);
        } else {
            setIsVisible(true);
            setTimeout(() => setIsOpen(true), 10);
        }
    };

    return (
        <Body>
            <NavigationContainer onClick={toggleMenu} $isOpen={isOpen}>
                <NavigationRose src={NavRose} alt="navigation icon" $isOpen={isOpen} />
                <NavigationThorns src={NavThorns} alt="navigation icon" $isOpen={isOpen} />
            </NavigationContainer>
            <FullScreenNavigation $isOpen={isOpen} $isVisible={isVisible} className={`navbar ${isOpen ? 'open' : ''}`}>
                <SocialLinks>
                    <a href="https://github.com/orgs/byteluv-network" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a href="https://x.com/ByteluvNetwork" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                    <a href="https://t.me/byteluv" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faTelegram} />
                    </a>
                </SocialLinks>
                <NavigationSections>
                    <NavigationSection>
                        <NavHeader>
                            Home
                        </NavHeader>
                        <Divider />
                        <NavBody>
                            <CardButton header="Features" subHeader="Online Dating with freedom" href='#howitworks' toggleMenu={toggleMenu} />
                            <CardButton header="$BLUV" subHeader="The native token of Byteluv" href='#bluv-token' toggleMenu={toggleMenu} />
                            <CardButton header="FAQ" subHeader="Frequently Asked Questions" href={'https://wiki.byteluv.network/category/faq'} />
                        </NavBody>
                    </NavigationSection>
                    <NavigationSection>
                        <NavHeader>
                            Learn
                        </NavHeader>
                        <Divider />
                        <NavBody>
                            <CardButton header="Wiki" subHeader="Read the Byteluv Wiki" href={'https://wiki.byteluv.network/'} />
                            <CardButton header="Tutorials" subHeader="Learn how to use Byteluv" href={'https://wiki.byteluv.network/category/byteluv-tutorial'} />
                            <CardButton header="PinkPaper" subHeader="Read the PinkPaper" href={'https://wiki.byteluv.network/category/pink-paper'} />
                        </NavBody>
                    </NavigationSection>
                    <NavigationSection>
                        <NavHeader>
                            Love
                        </NavHeader>
                        <Divider />
                        <NavBody>
                            <CardButton header="Start Dating" subHeader="Find your soulmate" href={'https://app.byteluv.network/'} />
                            <CardButton header="Verfier" subHeader="Become a Verifier" href={'https://app.byteluv.network/verifier'} />
                            {/* <CardButton header="Faucet" subHeader="Get free $BLUV" href={'https://app.byteluv.network/faucet'} /> */}
                        </NavBody>
                    </NavigationSection>
                    {/* <NavigationSection>
                        <NavHeader>
                            Coming Soon
                        </NavHeader>
                        <Divider />
                        <NavBody>
                            <CardButton header="AirDrop" subHeader="Register for the AirDrop" href={'https://app.byteluv.network/airdrop'} />
                            <CardButton header="Partners" subHeader="Our partners" />
                        </NavBody>
                    </NavigationSection> */}
                </NavigationSections>
            </FullScreenNavigation >
            <Outlet />
        </Body >
    );
};

export default NavigationBar;
