import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import rose1 from '../../assets/background-images/rose-background-5.svg';

const BgContainer = styled.div`
    display: flex;
    position: relative;
    height: auto;
    min-height: 110vh;
    width: 100vw;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    z-index: 1;
    overflow: hidden;

    @media (max-width: 800px) {
        min-height: 170vh;
    }

    @media (max-width: 600px) {
        min-height: 1400px;
    }
`;

const BgRose1 = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ $scrollPosition, $maxScroll }) => {
        const rotate = Math.min($scrollPosition / (1.5 * $maxScroll), 1) * 360;
        const scale = Math.min($scrollPosition / (1.5 * $maxScroll), 1);
        return `translate(-50%, -50%) rotate(${rotate}deg) scale(${scale})`;
    }};
    opacity: ${({ $scrollPosition, $maxScroll }) => Math.min($scrollPosition / $maxScroll, 1)};
    height: 100%;
    width: auto;
    z-index: 2;
    transition: transform 0.7s, opacity 0.7s;

    @media (max-width: 1200px) {
        height: 80%;
    }

    @media (max-width: 800px) {
        width: 100%;
        height: auto;
    }
`;


const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5% 0;
    justify-content: flex-start;
    align-items: center;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1) 0%, rgba(128, 22, 42, 0.5) 40%, rgba(0, 0, 0, 0.7) 60%, rgba(0, 0, 0, 1) 100%);


    z-index: 3;

    @media (max-width: 800px) {
        padding: 10vh 0;
    }
`;

const FlowerBackground = ({ children }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [inView, setInView] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const currentContainer = containerRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting);
            },
            { threshold: 0.3 }
        );

        if (currentContainer) {
            observer.observe(currentContainer);
        }

        return () => {
            if (currentContainer) {
                observer.unobserve(currentContainer);
            }
        };
    }, [containerRef]); // eslint-disable-next-line

    useEffect(() => {
        const handleScroll = () => {
            if (inView) {
                setScrollPosition(window.scrollY);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [inView]); // eslint-disable-next-line

    const maxScroll = 1500; // Increased $maxScroll value to slow down animation

    return (
        <BgContainer ref={containerRef}>
            <BgRose1 src={rose1} $scrollPosition={scrollPosition} $maxScroll={maxScroll} />
            <Overlay>
                {children}
            </Overlay>
        </BgContainer>
    );
};

export default FlowerBackground;
