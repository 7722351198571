import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Rose4Svg from '../../assets/background-images/rose-background-4.svg';

const BgContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100vw;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    z-index: 1;
`;

const Rose = styled.img`
    position: absolute;
    top: 50%;
    transform: translateY(-50%) ${(props) => props.translate} rotate(90deg);
    height: 300px;
    width: auto;
    padding: 0;
    margin: 0;
    transition: transform 0.1s linear; /* Smooth, reactive animation */
    z-index: 1;
    pointer-events: none;

    @media (max-width: 800px) {
        height: 200px;
    }

    @media (max-width: 600px) {
        height: 125px;
    }

    @media (max-width: 400px) {
        height: 100px;
    }
`;

const Rose1 = styled(Rose)`
    left: 50%;

    @media (max-width: 800px) {
        left: 60%;
    }

    @media (max-width: 600px) {
        left: 70%;
    }

    @media (max-width: 400px) {
        left: 80%;
    }
`;

const Rose2 = styled(Rose)`
    right: 50%;

    @media (max-width: 800px) {
        right: 60%;
    }

    @media (max-width: 600px) {
        right: 70%;
    }

    @media (max-width: 400px) {
        right: 80%;
    }
`;

const ChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    margin: 20vh 0;

    a {
        font-size: 5rem;
        color: white;
        padding: 0rem;
        margin: 0.5rem;
        transition: color 0.3s ease, opacity 0.3s ease;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        opacity: ${({ $scrollPosition }) => $scrollPosition}; /* Dynamically update opacity */

        &:hover {
            color: var(--pink-color);
            border-color: var(--pink-color);
        }

        @media (max-width: 800px) {
            font-size: 4rem;
        }

        @media (max-width: 600px) {
            font-size: 3rem;
        }

        @media (max-width: 400px) {
            font-size: 2rem;
        }
    }
`;

const LinksBackground = ({ children }) => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const leftRoseRef = useRef(null);
    const rightRoseRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            // Calculate the scroll progress as a percentage
            const maxScroll = document.body.scrollHeight - window.innerHeight;
            const progress = Math.min(window.scrollY / maxScroll, 1); // Clamp between 0 and 1
            setScrollProgress(progress);
            updateRosePositions(progress);
        };

        const updateRosePositions = (progress) => {
            const leftTranslate = `translateX(-${progress * 140}%)`;
            const rightTranslate = `translateX(${progress * 140}%)`;

            if (leftRoseRef.current) {
                leftRoseRef.current.style.transform = `translateY(-50%) ${leftTranslate} rotate(90deg)`;
            }

            if (rightRoseRef.current) {
                rightRoseRef.current.style.transform = `translateY(-50%) ${rightTranslate} rotate(90deg)`;
            }
        };

        const onScrollThrottled = () => {
            requestAnimationFrame(handleScroll);
        };

        window.addEventListener('scroll', onScrollThrottled);
        return () => {
            window.removeEventListener('scroll', onScrollThrottled);
        };
    }, []);

    return (
        <BgContainer>
            <Rose1 src={Rose4Svg} alt="Rose 1" ref={leftRoseRef} />
            <Rose2 src={Rose4Svg} alt="Rose 2" ref={rightRoseRef} />
            <ChildrenContainer $scrollPosition={scrollProgress}>
                {children}
            </ChildrenContainer>
        </BgContainer>
    );
};

export default LinksBackground;
